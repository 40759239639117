// :cow: Cosmose CONFIDENTIAL :iso:
import { Action, createReducer, on } from '@ngrx/store';
import { getCategoryListFailed, getCategoryListRequested, getCategoryListSucceeded } from './select-category.actions';
import { CategoryListResponse } from '@par/app/core/model/dictionaries.response';

export const STATE_NAME_SELECT_CATEGORY = 'select-category';

export interface State {
  response?: CategoryListResponse;
  responseError: boolean;
  responseLoading: boolean;
}

const initialState: State = {
  response: undefined,
  responseError: false,
  responseLoading: false,
};

const reducer = createReducer(
  initialState,
  on(getCategoryListRequested, (state) => ({
    ...state,
    response: undefined,
    responseLoading: true,
    responseError: false,
  })),
  on(getCategoryListSucceeded, (state, {response}) => ({
    ...state,
    responseLoading: false,
    response,
    responseError: false,
  })),
  on(getCategoryListFailed, (state) => ({...state, responseLoading: false, responseError: true})),
);

export function selectCategoryReducer(state: State | undefined, action: Action): any {
  return reducer(state, action);
}

